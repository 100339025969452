<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="!loading" style="height: 270px;padding: 10px;background-color: rgba(223,201,236,0.32);">
          <div
            class="fileInputDialog d-flex flex-column align-center"
            style="border-radius: 5px;margin-top: 20px"
            @dragleave="dragleave"
            @dragover="dragover"
            @drop="drop"
          >
            <input
              id="assetsFieldHandle"
              ref="file"
              type="file"
              name="fields[assetsFieldHandle][]"
              class="w-px h-px opacity-0 overflow-hidden absolute ma-1"
              style="height: 200px"
              @change="onChange"
            />
            <label
              style="margin-bottom: 25px"
              for="assetsFieldHandle"
              class="block cursor-pointer"
            >
              Przeciągnij pliki
              lub <span class="underline">kliknij tutaj</span> aby dodać plik
            </label>
          </div>
        </v-card>
        <div class="text-center" v-if="loading">
          <v-progress-circular
            :size="120"
            class="flex-column align-center"
            style="margin: auto;margin-top: 150px;"
            indeterminate
            color="primary"
          >
            Przesyłanie pliku
          </v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      color="error"
    >
      {{ snackbarContent }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import FileService from "@/services/FileService";
import CCService from "@/services/CCService";
export default {
  name: 'CCService',
  data: () => ({
    search: '',
    icons: {},
    items: [],
    loading: false,
    fileList: [],
    snackbar: false,
    snackbarContent: '',
    min: 3000,
    max: 60000,
    range: [3000, 30000],
    headers: [
      {text: 'KWOTA', value: 'value'},
      {text: 'OKRES', value: 'period'},
      {text: 'UBEZPIECZENIE', value: 'insurance'},
      {text: 'PROWIZJA MIN', value: 'minCommission'},
      {text: 'PROWIZJA MAX', value: 'maxCommission'},
      {text: 'KONSOLIDACJA', value: 'consolidation'},
      {text: 'RATA', value: 'installment'},
      {text: 'RATA KONSOLIDACJA', value: 'installmentCons'}
    ]
  }),
  computed: {
    ccItems () {
      if (this.range[0] !== this.min || this.range[1] !== this.max) {
        return this.items.filter(i => (i.value >= this.range[0]) & (i.value <= this.range[1]))
      } else {
        return this.items
      }
    }
  },
  methods: {
    onChange() {
      this.loading = true
      this.fileList = [...this.$refs.file.files]
        FileService.uploadFileCC(this.$refs.file.files[0]).then(response => {
          this.loading = false
          this.$router.push(`/cc/${response.data.googleDriveId}`)
        }).catch(error => {
          this.loading = false
          this.snackbar = true
          this.snackbarContent = 'Błąd przesyłania pliku'
          console.info(error.message)
      })
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    dragover(event) {
      event.preventDefault()

      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    }
  }
}
</script>

<style scoped>

</style>
